import * as React from "react"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import BackgroundSmoke from '../../../static/backgrounds/background_smoke.png';
import BackgroundSmokeMobile from '../../../static/backgrounds/background_smoke_mobile.png';


const MOBILE_BREAKPOINT = "992px";

const Container = styled.div`
    position: relative;
    max-width: 1504px;
    margin: 0 auto 100px;
    background-color: #6D6E71;
    border-radius: 3px;
    overflow: hidden;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        max-width: 373px;
    }
`;

const Background = styled.div`
    height: 410px;
    background-image: url(${BackgroundSmoke});
    background-repeat: no-repeat;
    background-position: right center;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        height: 500px;
        background-image: url(${BackgroundSmokeMobile});
        background-size: auto 500px;
        background-position: right;
    }
`;

const BackgroundGradient = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1504px;
    height: 410px;
    background: linear-gradient(83.84deg,#6D6E71 53%,rgba(109,110,113,0) 130%);

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        height: 500px;
        background: linear-gradient(83.84deg,#6D6E71 18%,rgba(109,110,113,0) 50%);
    }
`;

const Content = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 60px;
    padding-right: 200px;
    font-family: 'D-DINExp';
    color: #FFFFFF;
    border-radius: inherit;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        padding: 0 30px;
    }
`;

const Title = styled.h3`
    font-size: 36px;
    line-height: 120%;
    font-weight: bold;
    letter-spacing: 0.2px;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        font-size: 24px;
        text-align: center;
    }
`;

const Point = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 133%;
    font-weight: 400;
    overflow: visible;
    max-width: 900px;

    .point-icon {
        min-width: 32px;
        min-height: 32px;
        margin-right: 30px;
    }

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        align-items: flex-start;
        padding-left: 20px;
        font-size: 16px;
    }
`;

const OurMission = () => (
    <Container>
        <Background/>
        <BackgroundGradient/>
        <Content>
            <Title>
                It is our objective to ensure every customer that comes 
                into one of our stores leaves with three things:
            </Title>
            <Point>
                <StaticImage src='../../../static/svg/Heart.svg' alt="Heart" className="point-icon" width={32}/>
                A cannabis product or weed accessory that's going to facilitate an amazing 
                cannabis experience for them, catered to their tastes and preferences.
            </Point>
            <Point>
                <StaticImage src='../../../static/svg/GraduationCap.svg' alt="Graduation Cap" className="point-icon" width={32}/>
                More product knowledge than when they entered.
            </Point>
            <Point>
                <StaticImage src='../../../static/svg/SmileyWink.svg' alt="Smiley Wink" className="point-icon" width={32}/>
                A smile on their face!
            </Point>
        </Content>
    </Container>
)

export default OurMission;