import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import BackgroundGuelph from '../../../static/backgrounds/Guelph.png';
import BackgroundCambridge from '../../../static/backgrounds/Cambridge.png';

const MOBILE_BREAKPOINT = '768px';

const Container = styled.div`
  margin-top: 120px;
  margin-bottom: 40px;
  text-align: center;
`;

const Title = styled.h2`
  position: relative;
  z-index: 5;
  margin-bottom: 30px;
  padding: 0 50px;
  font-family: 'D-DINExp';
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: black;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 24px;
  }
`;

const Description = styled.div`
  position: relative;
  z-index: 5;
  padding: 0 20px;
  font-family: 'D-DINExp';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #6d6e71;
`;

const LocationContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0,1fr));

  .brush-stroke {
    position: absolute;
    left: 0;
    right: 0;
    height: 140px;

    &--top {
      top: 0;
      transform: translateY(-85px);
    }

    &--bottom {
      bottom: 0;
      transform: translateY(120px);
    }
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const Location = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 35% 10px 60px;
  text-align: center;
  color: white;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 150px 0;
  }
`;

const LocationDescription = styled.div`
  margin-bottom: 60px;
  padding: 0 30px;
  font-family: 'D-DINExp';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const LocationName = styled.h3`
  margin-bottom: 50px;
  font-family: 'Gloss-And-Bloom';
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: 0.2px;

  @media screen and (min-width: ${MOBILE_BREAKPOINT}) and (max-width: 1200px) {
    font-size: 5vw;
  }
`;

const Subtitle = styled.h4`
  position: relative;
  z-index: 5;
  margin-top: 60px;
  font-family: 'Space-Age';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #e94799;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0 55px;
  }
`;

const LocationSection = () => (
  <Container>
    <Title>Southern Ontario's 'Retail Stores Near Me'</Title>
    <Description>
      If you live in Southern Ontario, then we have good news for you! Ronin
      Cannabis has three locations open and ready to serve you.
    </Description>
    <LocationContainer>
      <Location src={BackgroundGuelph}>
        <LocationDescription>
          If you're looking for a new Guelph retail store, we've got you
          covered!
        </LocationDescription>
        <LocationName>Guelph</LocationName>
        <Link to="/guelph">
          <button type="button">location</button>
        </Link>
      </Location>
      <Location src={BackgroundCambridge}>
        <LocationDescription>
          Want to buy weed in Cambridge? No problem, there's a Ronin store there
          to assist you.
        </LocationDescription>
        <LocationName>Cambridge</LocationName>
        <Link to="/cambridge">
          <button type="button">location</button>
        </Link>
      </Location>
      <StaticImage
        src="../../../static/svg/BrushStroke.svg"
        alt=""
        className="brush-stroke brush-stroke--top"
      />
      <StaticImage
        src="../../../static/svg/BrushStroke.svg"
        alt=""
        className="brush-stroke brush-stroke--bottom"
      />
    </LocationContainer>
    <Subtitle>Don't Settle for Just Any Weed Retail Store</Subtitle>
  </Container>
);

export default LocationSection;
