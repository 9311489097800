import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-family: 'D-DINExp', serif;
  font-size: 13px;
  font-weight: bold;
  color: #d95396;
  border: 2px solid #d95396;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  &:visited {
      color: #D95396;
  }
  &:hover {
    color: white;
    background: #D95396;
  }
  ${({ primary }) =>
    primary &&
    css`
      min-width: 250px;
      height: 45px;
      padding: 0 30px;
      font-family: 'Space-Age', serif;
      font-size: 24px;
      font-weight: normal;
      color: white;
      background: #d95396;
      border-color: transparent;
      box-shadow: 0 0 4px #e94799, 0 0 25px #e94799;
      &:visited {
        color: white;
      }
      &:hover {
        color: #D95396;
        background: white;
        box-shadow: 0px 0px 8px #E94799, 0px 0px 40px #E94799;
      }
    `}
`;

export default StyledLink;
