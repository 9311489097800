import React from 'react';
import styled from 'styled-components';

import { graphql, useStaticQuery, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const TreatYourSelfStyle = styled.div`
  width: 100%;

  .banner-image,
  .banner-image::before,
  .banner-image::after {
    background-position: center top;
  }

  @media screen and (max-width: 450px) {
    .banner-image,
    .banner-image::before,
    .banner-image::after {
      height: 55vh !important;
      background-position-y: top;
      background-position-x: 35%;
    }
  }
`;

const TreatYourSelfText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 40px auto;
  max-width: 1000px;
  margin-bottom: -20px;

  h2 {
    padding: 60px 0px;
    color: black;
    z-index: 2;
    @media screen and (max-width: 450px) {
      padding-bottom: 10px;
    }
  }

  p {
    opacity: 0.7;
    padding: 0 5px;
  }

  padding: 0 15px;
  @media screen and (max-width: 450px) {
    margin-bottom: 0;
  }
`;

const OverlayGradient = styled.div`
  width: 100%;
  height: 70%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 0) 100%
  );

  display: flex;
  justify-content: center;

  button {
    margin-top: 4vw;
    display: inline-block;
    height: 50px;
  }
`;

export default function BottomHero({ title, content }) {
  // ----Gatsby Background Image Set-Up-----
  const { bannerImage } = useStaticQuery(
    graphql`
      query {
        bannerImage: file(relativePath: { eq: "flower-in-pot.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const image = getImage(bannerImage);
  const bgImage = convertToBgImage(image);

  return (
    <TreatYourSelfStyle>
      <TreatYourSelfText>
        <h2>{title}</h2>
        {content.map((block) => (
          <p>{block}</p>
        ))}
      </TreatYourSelfText>
      <BackgroundImage
        Tag="div"
        {...bgImage}
        preserveStackingContext
        className="banner-image"
        style={{
          width: '100%',
          height: '70vh',
          backgroundPosition: '',
        }}
      >
        <OverlayGradient>
          <button><Link to="/locations">View Menus</Link></button>
        </OverlayGradient>
      </BackgroundImage>
    </TreatYourSelfStyle>
  );
}
