import styled from 'styled-components';

const MOBILE_BREAKPOINT = '768px';

const StyledContainer = styled.div`
  padding: 0 ${({ padding }) => padding || '200px'};

  @media screen and (max-width: ${(props) =>
      props.breakpoint || MOBILE_BREAKPOINT}) {
    padding: 0 ${({ paddingMobile }) => paddingMobile || '20px'};
  }
`;

export default StyledContainer;
