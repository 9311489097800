import React from 'react';
import { graphql } from 'gatsby';
import HomePageHero from '../components/HomePageHero';
import BottomHero from '../components/BottomHero';
import StyledContainer from '../components/organisms/StyledContainer';
import CategorySection from '../components/templates/CategorySection';
import OurMission from '../components/templates/OurMission';
import LocationSection from '../components/templates/LocationSection';

import Flowers from "../assets/images/category_images/flowers.png"
import Prerolls from "../assets/images/category_images/prerolls.png"
import Edibles from "../assets/images/category_images/edibles.png"
import Vapes from "../assets/images/category_images/vapes.png"
import Oils from "../assets/images/category_images/oils.png"
import Beverages from "../assets/images/category_images/beverages.png"
import Grinders from "../assets/images/category_images/grinders.png"
import Pipes from "../assets/images/category_images/pipes.png"
import Papers from "../assets/images/category_images/rollingPapers.png"
import Topicals from "../assets/images/category_images/topicals.png"
import Tea from "../assets/images/category_images/drinks.png"
import Edibles2 from "../assets/images/category_images/edibles_2.png"
import Chocolate from "../assets/images/category_images/chocolate.png"

import Review from '../components/Review';
import ContactUs from '../components/ContactUs';
import SEO from '../components/SEO';


const categoriesDataA = [
    {
        src: Flowers,
        title: 'Flowers',
        slug: '/livemenu?dtche[category]=flower',
    },
    {
        src: Prerolls,
        title: 'Prerolls',
        slug: '/livemenu?dtche[category]=pre-rolls',
    },
    {
        src: Edibles,
        title: 'Edibles',
        slug: '/livemenu?dtche[category]=edibles',
    },
    {
        src: Vapes,
        title: 'Vapes',
        slug: '/livemenu?dtche[category]=vaporizers',
    },
    {
        src: Oils,
        title: 'Oils',
        slug: '/livemenu?dtche[category]=concentrates',
    },
    {
        src: Beverages,
        title: 'Beverages',
        slug: '/livemenu?dtche[subcategories]=drinks&dtche[category]=edibles',
    },
];

const categoriesDataB = [
    {
        src: Grinders,
        title: "Grinders",
        slug: "/livemenu?dtche[subcategories]=grinders&dtche[category]=accessories",
    },
    {
        src: Pipes,
        title: "Pipes",
        slug: "/livemenu?dtche[subcategories]=glassware&dtche[category]=accessories",
    },
    {
        src: Papers,
        title: "Rolling Papers",
        slug: "/livemenu?dtche[subcategories]=papers-rolling-supplies&dtche[category]=accessories",
    },
    {
        src: Topicals,
        title: "Cannabis Topicals",
        slug: "/livemenu?dtche[category]=topicals",
    },
    {
        src: Tea,
        title: "Cannabis Drinks",
        slug: "/livemenu?dtche[subcategories]=drinks&dtche[category]=edibles",
    },
    {
        src: Vapes,
        title: "Vape Cartridges",
        slug: "/livemenu?dtche[subcategories]=cartridges&dtche[category]=vaporizers",
    },
    {
        src: Chocolate,
        title: "Edible Chocolate Bars",
        slug: "/livemenu?dtche[subcategories]=chocolates&dtche[category]=edibles",
    },
    {
        src: Edibles,
        title: "Edible Gummies",
        slug: "/livemenu?dtche[subcategories]=gummies&dtche[category]=edibles",
    },
]

export default function HomePage({data}){
    return(
        <>
            <SEO 
                title="Your Local Cannabis Retail store"
                description="We don't believe just in smoking bowls; we believe in helping 
                our customers create an entire cannabis experience. Do you want an uplifting, energetic high that will allow you to get some 
                work done, or a mellow, relaxing high that will leave you vegging out on the couch?"
            />
            <HomePageHero 
                subtitle={""}
                title={"Elevate Your Experience At Ronin Cannabis"}
                content={[`We understand that you have a lot of choices available 
                        to you when you start searching for a 'Retail store near me', 
                        but what sets Ronin apart`]}
                buttontext={"shop now"}
                bannerImage={data.bannerImage}
            />
            <CategorySection
                data={categoriesDataB}
                columns='4'
                title='A Fantastic Selection of Weed Accessories, Edibles and Beverages'
                description={[
                    `Whether you're looking for rolling papers, a new weed grinder, 
                    you want to try out a vape pen, we've got a stunning collection 
                    of weed accessories that can help you enjoy cannabis in whatever 
                    form you'd prefer.`
                ]}
            >
            </CategorySection>
            
                <OurMission/>
            
            <StyledContainer breakpoint="1200px">
                <CategorySection
                    data={categoriesDataA}
                    columns='3'
                    subtitle="All the Cannabis Products You Could Ever Want"
                    title='We choose our products with integrity, so you can feel good coming back time after time'
                    description={[
                        `We don't believe just in smoking bowls; we believe in helping 
                        our customers create an entire cannabis experience.`,
                        `Do you want an uplifting, energetic high that will allow you to get some 
                        work done, or a mellow, relaxing high that will leave you vegging out on the couch?`
                    ]}
                >               
                </CategorySection>
            </StyledContainer>
            
            <LocationSection/>
            <BottomHero 
                title={"Treat Yourself to the Best and Visit Ronin Cannabis"}
                content={[
                    `With only legal, regulated products available for sale, you'll never have to
                    worry about whether our edibles you buy are safe to eat, or our cannabis buds
                    are safe to smoke.`,

                    `We guarantee that every cannabis product and weed accessory we carry is 
                    approved and licensed for sale by the Government of Canada. These vetted 
                    products go through rigorous testing processes, in order to ensure you're 
                    always protected.`,

                    `Ronin is committed to only providing our customers with products we know can
                    be trusted and relied on for consistency. That protection and peace of mind is 
                    just part of the reason why Ronin is the best cannabis Retail store in Ontario.`,

            `We guarantee that every cannabis product and weed accessory we carry is 
                        approved and licensed for sale by the Government of Canada. These vetted 
                        products go through rigorous testing processes, in order to ensure you're 
                        always protected.`,

            `Ronin is committed to only providing our customers with products we know can
                        be trusted and relied on for consistency. That protection and peace of mind is 
                        just part of the reason why Ronin is the best cannabis Retail store in Ontario.`,

            `As you can see, there are tons of reasons why you should visit one of Ronin's new 
                        cannabis retail locations. Check out our product selection, and see for yourself why 
                        you're making the right choice when you visit Ronin Cannabis.`,
            ]}
        />
    </>
    );
}

export const query = graphql`
    query{
            faq: allSanityFaq {
                nodes {
                    id
                    question
                    answer
                    location
                }
            }

        bannerImage: file(relativePath: { eq: "ronin-bg.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 1900
                    quality:90
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
    }
`;


